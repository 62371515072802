import { SVGProps } from 'react';

export const Step1IconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    overflow={'visible'}
    enableBackground="new 0 0 80 80"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m69.9 0h-59.8c-5.6 0-10.1 4.5-10.1 10v60c0 5.5 4.5 10 10.1 10h59.9c5.5 0 10-4.5 10-10v-60c0-5.5-4.5-10-10.1-10z"
      fill="#f8f8f8"
    />
    <path
      d="m31 36.5h-14c-.8 0-1.6-.3-2.1-.9s-.9-1.3-.9-2.1v-14c0-.8.3-1.6.9-2.1.6-.6 1.3-.9 2.1-.9h14c.8 0 1.6.3 2.1.9.6.6.9 1.3.9 2.1v14c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9zm-14-18c-.3 0-.5.1-.7.3s-.3.4-.3.7v14c0 .3.1.5.3.7s.4.3.7.3h14c.3 0 .5-.1.7-.3s.3-.4.3-.7v-14c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3z"
      fill="#20201d"
    />
    <path
      d="m31 67.5h-14c-.8 0-1.6-.3-2.1-.9s-.9-1.3-.9-2.1v-14c0-.8.3-1.6.9-2.1s1.3-.9 2.1-.9h14c.8 0 1.6.3 2.1.9s.9 1.3.9 2.1v14c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9zm-14-18c-.3 0-.5.1-.7.3s-.3.4-.3.7v14c0 .3.1.5.3.7s.4.3.7.3h14c.3 0 .5-.1.7-.3s.3-.4.3-.7v-14c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3z"
      fill="#20201d"
    />
    <path
      d="m44.8 13.5h19c.8 0 1.6.3 2.1.9s.9 1.3.9 2.1v19c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9h-19c-.8 0-1.6-.3-2.1-.9s-.9-1.3-.9-2.1v-19c0-.8.3-1.6.9-2.1s1.3-.9 2.1-.9z"
      fill="#5482db"
    />
    <path
      d="m61.8 67.5h-14c-.8 0-1.6-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1v-14c0-.8.3-1.6.9-2.1.6-.6 1.3-.9 2.1-.9h14c.8 0 1.6.3 2.1.9.6.6.9 1.3.9 2.1v14c0 .8-.3 1.6-.9 2.1-.6.6-1.3.9-2.1.9zm-14-18c-.3 0-.5.1-.7.3s-.3.4-.3.7v14c0 .3.1.5.3.7s.4.3.7.3h14c.3 0 .5-.1.7-.3s.3-.4.3-.7v-14c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3z"
      fill="#20201d"
    />
    <path
      d="m77.8 28.8c-.3.3-.6.7-.8 1.1-.8-.5-1.8-.7-2.8-.4-.9.2-1.8.8-2.3 1.6-.1.1-.1.2-.2.3-.7-.6-1.7-.7-2.7-.4-.9.3-1.7.9-2.2 1.7l-2.6-2.9c-.7-.7-1.6-1.1-2.5-1.2-1 0-1.9.3-2.6.9s-1.2 1.6-1.2 2.5c-.1 1 .3 1.9.9 2.6l10.6 11.8-2.5-.6h-.1c-1.1-.2-2.2.1-3.1.7s-1.6 1.6-1.8 2.7 0 2.2.6 3.2c.6.9 1.5 1.6 2.6 1.9l11.5 3.2c1.4.3 2.8.3 4.2 0 1.4-.4 2.7-1 3.7-2l5.3-4.8c1.8-1.7 3-4 3.1-6.5s-.7-4.9-2.4-6.8l-7.5-8.4c-.7-.7-1.6-1.2-2.5-1.2-1 0-2 .3-2.7 1"
      fill="#fff"
    />
    <path d="m77.8 28.8c-.3.3-.6.7-.8 1.1-.4-.3-.9-.4-1.3-.5-.5-.1-1-.1-1.4.1-.5.1-.9.3-1.3.6s-.7.6-1 1c-.1.1-.1.2-.2.3-.4-.2-.9-.4-1.4-.4-.5-.1-1 0-1.4.1-.5.1-.9.3-1.3.6s-.7.7-.9 1.1l-2.6-2.9c-.3-.4-.7-.7-1.1-.9s-.9-.3-1.4-.4c-.5 0-1 0-1.4.2-.5.2-.9.4-1.2.7-.4.3-.7.7-.9 1.1s-.3.9-.4 1.4c0 .5 0 1 .2 1.4.2.5.4.9.7 1.2l10.6 11.8-2.5-.6h-.1c-1.1-.2-2.2.1-3.1.7s-1.6 1.6-1.8 2.7 0 2.2.6 3.2c.6.9 1.5 1.6 2.6 1.9l11.5 3.2c1.4.3 2.8.3 4.2 0 1.4-.4 2.7-1 3.7-2l5.3-4.8c1.8-1.7 3-4 3.1-6.5s-.7-4.9-2.4-6.8l-7.4-8.4c-.7-.7-1.6-1.2-2.5-1.2-1 0-2 .3-2.7 1zm10.9 10.4c1.2 1.4 1.8 3.1 1.7 5-.1 1.8-.9 3.5-2.3 4.7l-5.3 4.8c-.8.7-1.7 1.2-2.7 1.4-1 .3-2 .3-3 0l-11.5-3.2c-.5-.1-.8-.4-1.1-.8s-.3-.9-.3-1.3c.1-.5.3-.9.7-1.1.4-.3.8-.4 1.3-.3l10.3 2.6-15.9-18c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.3-.1-.4 0-.2 0-.3.1-.4s.2-.3.3-.4.2-.2.4-.2c.1-.1.3-.1.4-.1.2 0 .3 0 .4.1s.3.2.4.3l8.4 9.3 1.9-1.7-3.4-3.8c-.2-.1-.2-.3-.3-.4s-.1-.3-.1-.4c0-.2 0-.3.1-.4s.2-.3.3-.4.2-.2.4-.2c.1-.1.3-.1.4-.1.2 0 .3 0 .4.1s.3.2.4.3l3.4 3.8 1.9-1.7-1.9-2.2c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.3-.1-.4 0-.2 0-.3.1-.4s.2-.3.3-.4.2-.2.4-.2c.1-.1.3-.1.4-.1.2 0 .3 0 .4.1s.3.2.4.3l1.9 2.2 1.9-1.7-.4-.4c-.2-.2-.3-.5-.3-.8s.2-.6.4-.8.5-.3.8-.3.6.2.8.4z" />
    <path d="m55.9 24-1.9 1.7 2.3 2.6 1.9-1.7z" />
    <path d="m59.8 22.5v3.5h2.5v-3.5z" />
    <path d="m66.2 24-2.3 2.5 1.8 1.7 2.3-2.5z" />
    <path d="m52.1 29.5-.3 2.5 3.4.3.2-2.5z" />
    <path d="m55.7 34-2.8 2 1.5 2 2.8-2.1z" />
  </svg>
);

export const Step2IconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    overflow={'visible'}
    enableBackground="new 0 0 80 80"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m70 0h-60c-5.52285 0-10 4.47715-10 10v60c0 5.5229 4.47715 10 10 10h60c5.5229 0 10-4.4771 10-10v-60c0-5.52285-4.4771-10-10-10z"
      fill="#f8f8f8"
    />
    <path
      d="m20 20h13c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v13c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787h-13c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-13c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787z"
      fill="#a5a9fb"
    />
    <path
      d="m45 46h13c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v13c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787h-13c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-13c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787z"
      fill="#f7e68b"
    />
    <path
      d="m20 46h13c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v13c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787h-13c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-13c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787z"
      fill="#f6b18a"
    />
    <path
      d="m43 18h17c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v17c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787h-17c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-17c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787z"
      fill="#fff"
    />
    <path
      d="m60 41h-17c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-17c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787h17c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v17c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787zm-17-21c-.2652 0-.5196.1054-.7071.2929s-.2929.4419-.2929.7071v17c0 .2652.1054.5196.2929.7071s.4419.2929.7071.2929h17c.2652 0 .5196-.1054.7071-.2929s.2929-.4419.2929-.7071v-17c0-.2652-.1054-.5196-.2929-.7071s-.4419-.2929-.7071-.2929z"
      fill="#20201d"
    />
    <path
      d="m45 20h13c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v13c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787h-13c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-13c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787z"
      fill="#e89296"
    />
    <path
      d="m58 39h-13c-.7956 0-1.5587-.3161-2.1213-.8787s-.8787-1.3257-.8787-2.1213v-13c0-.7956.3161-1.5587.8787-2.1213s1.3257-.8787 2.1213-.8787h13c.7956 0 1.5587.3161 2.1213.8787s.8787 1.3257.8787 2.1213v13c0 .7956-.3161 1.5587-.8787 2.1213s-1.3257.8787-2.1213.8787zm-13-17c-.2652 0-.5196.1054-.7071.2929s-.2929.4419-.2929.7071v13c0 .2652.1054.5196.2929.7071s.4419.2929.7071.2929h13c.2652 0 .5196-.1054.7071-.2929s.2929-.4419.2929-.7071v-13c0-.2652-.1054-.5196-.2929-.7071s-.4419-.2929-.7071-.2929z"
      fill="#fff"
    />
    <path
      d="m63 8c1.78 0 3.5201.52784 5.0001 1.51677 1.4801.98893 2.6336 2.39453 3.3148 4.03903.6812 1.6446.8594 3.4542.5122 5.2-.3473 1.7458-1.2045 3.3495-2.4631 4.6082-1.2587 1.2586-2.8624 2.1158-4.6082 2.4631-1.7458.3472-3.5554.169-5.2-.5122-1.6445-.6812-3.0501-1.8347-4.039-3.3148-.9889-1.48-1.5168-3.2201-1.5168-5.0001 0-2.3869.9482-4.6761 2.636-6.364 1.6879-1.68779 3.9771-2.636 6.364-2.636z"
      fill="#20201d"
    />
    <path
      d="m63 28c-2.1756 0-4.3023-.6451-6.1113-1.8538-1.8089-1.2087-3.2188-2.9267-4.0514-4.9367-.8325-2.01-1.0504-4.2217-.6259-6.3555.4244-2.1338 1.4721-4.0938 3.0104-5.63217 1.5384-1.53838 3.4984-2.58603 5.6322-3.01046 2.1338-.42444 4.3455-.2066 6.3555.62596s3.728 2.24246 4.9367 4.05137c1.2087 1.809 1.8538 3.9357 1.8538 6.1113-.0034 2.9163-1.1635 5.7122-3.2256 7.7744-2.0622 2.0621-4.8581 3.2222-7.7744 3.2256zm0-19.99999c-1.78 0-3.5201.52784-5.0001 1.51677-1.4801.98892-2.6336 2.39452-3.3148 4.03912-.6812 1.6445-.8594 3.4541-.5122 5.1999.3473 1.7458 1.2045 3.3495 2.4632 4.6082 1.2586 1.2586 2.8623 2.1158 4.6081 2.4631 1.7458.3472 3.5554.169 5.2-.5122 1.6445-.6812 3.0501-1.8347 4.039-3.3148.989-1.48 1.5168-3.2201 1.5168-5.0001-.0029-2.3861-.952-4.6735-2.6392-6.3607-1.6872-1.68724-3.9747-2.63638-6.3608-2.63929z"
      fill="#f8f8f8"
    />
    <g fill="#fff">
      <path d="m65.9675 16.2729-2.9 2.9c-.1927.1926-.4539.3007-.7263.3006s-.5336-.1084-.7262-.3011c-.1925-.1926-.3006-.4539-.3005-.7263 0-.2724.1083-.5336.301-.7262l2.9-2.9c.0945-.099.2079-.1781.3334-.2327s.2607-.0836.3976-.0852.2727.0242.3995.0758c.1267.0517.2419.1282.3387.225s.1732.2119.2249.3387c.0516.1268.0774.2626.0758.3995-.0016.1368-.0305.272-.0851.3976-.0546.1255-.1338.2388-.2328.3333" />
      <path d="m61.611 19.179-1.578-1.579c-.1926-.1927-.3007-.454-.3006-.7264s.1084-.5336.3011-.7261c.1927-.1926.454-.3007.7264-.3006s.5335.1084.7261.3011l1.577 1.578c.1852.194.2871.4528.284.721-.0032.2682-.1111.5246-.3008.7142-.1897.1897-.446.2976-.7142.3007-.2682.0032-.527-.0987-.721-.2839" />
    </g>
  </svg>
);

export const Step3IconSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    overflow={'visible'}
    enableBackground="new 0 0 80 80"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m70 0h-60c-5.5 0-10 4.5-10 10v60c0 5.5 4.5 10 10 10h60c5.5 0 10-4.5 10-10v-60c0-5.5-4.5-10-10-10z"
      fill="#f8f8f8"
    />
    <g fill="#20201d">
      <path d="m34 38h-13c-.8 0-1.6-.3-2.1-.9-.6-.5-.9-1.3-.9-2.1v-13c0-.8.3-1.6.9-2.1.6-.6 1.3-.9 2.1-.9h13c.8 0 1.6.3 2.1.9.6.6.9 1.3.9 2.1v13c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9zm-13-16v13h13v-13z" />
      <path d="m30 26h-5v5h5z" />
      <path d="m54 26h-5v5h5z" />
      <path d="m30 50h-5v5h5z" />
      <path d="m58 38h-13c-.8 0-1.6-.3-2.1-.9s-.9-1.3-.9-2.1v-13c0-.8.3-1.6.9-2.1.6-.6 1.3-.9 2.1-.9h13c.8 0 1.6.3 2.1.9.6.6.9 1.3.9 2.1v13c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9zm-13-16v13h13v-13z" />
      <path d="m34 62h-13c-.8 0-1.6-.3-2.1-.9-.6-.5-.9-1.3-.9-2.1v-13c0-.8.3-1.6.9-2.1.6-.6 1.3-.9 2.1-.9h13c.8 0 1.6.3 2.1.9s.9 1.3.9 2.1v13c0 .8-.3 1.6-.9 2.1s-1.3.9-2.1.9zm-13-16v13h13v-13z" />
      <path d="m48 44h-4v4h4z" />
      <path d="m52 48h-4v4h4z" />
      <path d="m48 52h-4v4h4z" />
      <path d="m56 44h-4v4h4z" />
      <path d="m60 48h-4v4h4z" />
      <path d="m57 57h-4v4h4z" />
    </g>
    <path
      d="m79 51c3.6 0 7 1.1 10 3 3 2 5.3 4.8 6.6 8.1 1.4 3.3 1.7 6.9 1 10.4s-2.4 6.7-4.9 9.2-5.7 4.2-9.2 4.9-7.1.3-10.4-1-6.1-3.6-8.1-6.6-3-6.4-3-10c0-4.8 1.9-9.4 5.3-12.7 3.3-3.4 7.9-5.3 12.7-5.3z"
      fill="#fff"
    />
    <path
      d="m79 83c-2.8 0-5.5-.8-7.8-2.4-2.3-1.5-4.1-3.7-5.2-6.3s-1.3-5.4-.8-8.1 1.9-5.2 3.8-7.2c2-2 4.5-3.3 7.2-3.8s5.5-.3 8.1.8 4.7 2.9 6.3 5.2 2.4 5 2.4 7.8c0 3.7-1.5 7.3-4.1 9.9s-6.2 4.1-9.9 4.1z"
      fill="#5482db"
    />
    <path
      d="m85.1 73.6c0 .2-.1.4-.2.6s-.4.2-.6.2h-11.4c-.1 0-.2 0-.3-.1-.1 0-.2-.1-.3-.2s-.1-.2-.2-.3c0-.1-.1-.2-.1-.3s0-.2.1-.3.1-.2.2-.3.2-.1.3-.2c.1 0 .2-.1.3-.1h11.3c.2 0 .4.1.6.2.2.4.3.6.3.8zm-7.1-2.6c.2.2.4.2.6.2s.4-.1.6-.2l2.9-2.9c.1-.2.2-.4.2-.6s-.1-.4-.2-.6c-.1-.1-.4-.2-.6-.2s-.4.1-.6.2l-1.5 1.5v-6.2c0-.2-.1-.4-.2-.6-.2-.1-.4-.2-.6-.2s-.4.1-.6.2-.2.3-.2.6v6.2l-1.5-1.4c-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.3-.1s-.2 0-.3.1c-.1 0-.2.1-.3.2s-.1.2-.2.3c0 .1-.1.2-.1.3s0 .2.1.3c0 .1.1.2.2.3z"
      fill="#fff"
    />
  </svg>
);
