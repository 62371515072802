import Button from 'components/ui/buttons/Button';
import styled from '@emotion/styled';
import { mediaMax, breakpoints } from 'styles';

export const CreateQRButton = styled(Button)`
  position: absolute;
  right: 120px;
  top: 40px;
  border: 1px solid;

  ${mediaMax(breakpoints.tabletXS)} {
    right: 75px;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  color: var(--qr-global-color-text-primary);
  padding: 10px;
  position: absolute;
  // -12px to center with logo (text)
  top: calc(50% - 12px);
  right: -10px;
  -webkit-tap-highlight-color: transparent;

  * {
    user-select: none;
  }
`;
