import styled from '@emotion/styled';
import { COLORS, mediaMin, breakpoints } from 'styles';

export const BlockTitle = styled.h2`
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  text-align: center;
  line-height: 26px;
  color: ${COLORS.PRIMARY_TEXT};

  ${mediaMin(breakpoints.laptopS)} {
    font-size: 25px;
  }
`;

export const BlockSubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.SECONDARY_TEXT};
  margin-top: 9px;

  ${mediaMin(breakpoints.laptopS)} {
    line-height: 18px;
    margin-top: 14px;
  }
`;
